/* General Styling */
.attendance-calendar-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

h1 {
  text-align: center;
  color: #333;
}

.react-calendar {
  margin: 0 auto;
  max-width: 700px;
  border-radius: 10px;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

/* Change the background color of the selected date */
.react-calendar__tile--active {
  background: #007bff !important; /* Blue background */
  color: white !important; /* White text */
  border-radius: 50%; /* Circle shape */
}

/* Change hover effect for better visibility */
.react-calendar__tile--active:hover {
  background: #0056b3 !important; /* Darker blue on hover */
}

.react-calendar__tile {
  position: relative;
  padding: 10px;
  background: #f9f9f9;
  border-radius: 5px;
  transition: background 0.2s;
}

.react-calendar__tile:hover {
  background: #e7f3ff;
}

/* Record Count under Date */
.tile-content .record-count {
  font-size: 12px;
  color: #3a3b3d;
  text-align: center;
}

/* Modal Styling */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 400px;
  z-index: 1000;
}

.record-list {
  max-height: 200px;
  overflow-y: auto;
  margin: 15px 0;
}

.record-item {
  padding: 5px 0;
  font-size: 14px;
}

.modal-field {
  margin-bottom: 15px;
}

.modal-field label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
}

.add-btn,
.save-btn {
  background: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.close-btn,
.cancel-btn {
  background: #f44336;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.add-btn:hover,
.save-btn:hover,
.close-btn:hover,
.cancel-btn:hover {
  opacity: 0.9;
}
/* Toggle button styling */
.view-toggle {
  text-align: center;
  margin-bottom: 20px;
}

.view-toggle button {
  margin: 0 10px;
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f0f0f0;
  transition: background-color 0.3s;
}

.view-toggle button.active-view {
  background-color: #007bff;
  color: white;
}

.view-toggle button:hover {
  background-color: #0056b3;
  color: white;
}
.attendance-list {
  justify-content: center;
  align-items: center;
}
